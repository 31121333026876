import React, { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Login from "./Login";
import Register from "./Register";


export default function NavLogin ({nav,setNav}) {

  const navigate = useNavigate();
  const [signUp,setSignUp] = useState(true);
  const closeNav = ()=>{
    setNav(false)
  }
  const logout =()=>{
    localStorage.clear();
    navigate('/');
    // notify('')
  }

  return (
    <div>
            <div className={nav ? "log__part open__log__part" : "log__part"}>
                 <div className="btn--close--part">
                    <i className="fa-solid fa-xmark" onClick={closeNav}></i>
                    </div>
                          
                    <div className='container--login--header'>
                  <button className='btn--header' style={{background :signUp ? "#f0c14b" :"rgb(15, 52, 90)" }} onClick={()=>setSignUp(true)}>Se connecter</button>
                  <button className='btn--header' style={{background :signUp ? "rgb(15, 52, 90)" :"#f0c14b" }} onClick={()=>setSignUp(false)}>S'inscrire</button>
              </div>
              {signUp ? <Login/> : <Register/>}
             </div>
        
        </div>
  )
}