export default [
      {
        id:1,
        title:"Cheese Burger",
        quantity:1,
        description:"Filet de boeuf au grill, oignon, poivron, sauce spéciale, laitues",
        price:"4500",
        img:"cheese.jpeg",
        category:"Burger"
      },
      {
        id:2,
        title:"Stake Burger",
        quantity:1,
        description:"Filet de boeuf au grill, oignon, poivron, sauce spéciale, laitues",
        price:"3500",
        img:"stake.jpg",
        category:"Burger"
      },
      {
        id:3,
        title:"Garba",
        quantity:1,
        description:"Poisson Thon,attiéké,piment,cube maggie",
        price:"1500",
        img:"stake.jpg",
        category:"Restaurant"
      },
      {
        id:4,
        title:"Chawarma",
        quantity:1,
        description:"viande hachée, tomate, tripe",
        price:"2000",
        img:"chawarma.png",
        category:"Street Foods"
      },
      {
        id:5,
        title:"Pizza Royale",
        quantity:1,
        description:"viange hachée, olive, thim ,tomate",
        price:"6000",
        img:"pizza-royale.jpg",
        category:"Street Foods"
      },
      {
        id:6,
        title:"Fruit de mer",
        quantity:1,
        description:"Gambasse, crevettes , 6 langoustines, 6 huîtres",
        price:"2000",
        img:"fruits.jpg",
        category:"Restaurant"
      },
      {
        id:7,
        title:"Dessert",
        quantity:1,
        description:"gateau au chocolat",
        price:"1500",
        img:"dessert.jpg",
        category:"Déjeuner"
      },
      {
        id:8,
        title:"Crepes",
        quantity:1,
        description:"lait , farine , oeufs,sucres",
        price:"2500",
        img:"crepes.jpg",
        category:"Déjeuner"
      },
      {
        id:9,
        title:"Poisson braisé",
        quantity:1,
        description:"Poisson braisé , attieke , condiments",
        price:"2000",
        img:"poisson.jpg",
        category:"Restaurant"
      },
      {
        id:10,
        title:"Grillades Brochettes",
        quantity:1,
        description:"grillade de brochette",
        price:"4500",
        img:"grillades.jpg",
        category:"Restaurant"
      },
      {
        id:11,
        title:"Poulet Burger",
        quantity:1,
        description:"poulet, oignon, poivron, sauce spéciale",
        price:"7500",
        img:"poulet.jpg",
        category:"Restaurant"
      },
  ];
  