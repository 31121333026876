import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';
import {
    getCartTotal,
    removeItem,
    decreaseItemQuantity,
    increaseItemQuantity,
  } from "../features/cartSlice";


export default function Commandes (){

    const [name,setName]=useState("");
    const [adresse,setAdresse]=useState("");
    const [numero,setTel]=useState("");
    const navigate = useNavigate();
    let id = useParams().id;

    const dispatch = useDispatch()

    const handleName = (e) =>{
        setName(e.target.value)
    }
    const handleAdresse = (e) =>{
        setAdresse(e.target.value)
    }
    const handleTel = (e) =>{
        setTel(e.target.value)
    }

      const { cart, totalQuantity, totalPrice } = useSelector((state) => state.allCart
      );
    
      useEffect(() => {
        dispatch(getCartTotal());
      }, [cart]);
    

    //   useEffect(() => {
    //     setCart(JSON.parse(localStorage.setItem("cart"))) 
    //     }, [cart])

        // useEffect(() => {
        //     setCart(JSON.parse(localStorage.setItem("carts")))
        //     }, [cart])

            
            // const Paiement= (e) =>{
            //     e.preventDefault();
            //     const dataPayment = {
            //       product_id: id,
            //       quantity,
            //       price,
            //       name,
            //       numero,
            //       adresse
            //     }
            //     fetch('http://localhost:8080/orders',{
            //           method:"POST",
            //           headers :{'Content-Type': "application/json"},
            //           body: JSON.stringify(dataPayment)
            //       }).then((res)=>res.json())
            //        .then((userData)=> {
            //         localStorage.clear();
            //         localStorage.setItem("user",JSON.stringify(userData));
            //         navigate('/');
            //         // notify("")    
            //         }).catch(err => {console.log(err)});
            //   };


    return (
        <div>
            <Navbar/>
            <div className="container">
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Mon panier</li>
            </ol>
            </nav>
            <h2>Votre panier</h2>
            <div className="main--orders">
            <div className="main--orders--container">
                <div className="main--orders--container--left">
                {cart?.map((data) => ( <div key={id} className="cart--articles">
                            {/* const totalPrice = carts.price * quantity */}
                            
                                        <div className="block--articles">
                                            <div className="articles">
                                                <img src={data.img} alt="" />
                                            </div>
                        
                                            <div className="articles--details">
                                                <p className="names--articles">{data.categorie}</p>
                                                <p className="names--articles">{data.title}</p>
                                                <p className="quantity">{data.quantity} unité (s)</p>
                                                <p className="price--articles"> {data.price} F CFA</p>
                                            </div>

                                        <div className="button-block">
                                            <div className="handle--qty">
                                            <button className="btn__minus" onClick={() => dispatch(decreaseItemQuantity(data.id))}>-</button>
                                            <div className="qty"><h5>{data.quantity}</h5></div>
                                            <button className="btn__plus" onClick={() => dispatch(increaseItemQuantity(data.id))}>+</button>
                                        </div>
                                    </div>
                                        </div>
                                        <div className="btn--remove--article"   onClick={() => dispatch(removeItem(data.id))}>
                                        <i className="fa-solid fa-trash"></i>
                                    </div>
                               
    
                                </div>
                             )) 
    }

                </div>

                <div className="main--orders--container--right">
                      <h6 className='main--orders--container--right--header'>Résumé du panier</h6> 
                      <div className='main--orders--container--right--body'>
                            <div className='article__details__order'>
                                <div className='article__details__order__content'>
                                    <h6>Total</h6>
                                    <p className="price">{totalPrice}FCFA</p>
                                </div>
                                {/* <div class="col-md-12">
                                <select class="form-select" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                </select>
                                </div> */}
                                {/* <div className='article__details__order__content'>
                                    <h5>Frais de livraison</h5>
                                    <p>5 000 FCFA</p>
                                </div> */}
                                {/* <div className='article__details__order__content'>
                                    <h5>Nº Coupon</h5>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                                        <label for="floatingInput">Nº coupon</label>
                                        </div>
                                </div> */}
                                {/* <div className='article__details__order__content'>
                                    <h5>Coupon</h5>
                                    <p>5 000 FCFA</p>
                                </div> */}
                            </div>
                            <div className='user__part'>
                                <h4>Vos coordonnés</h4>
                         <form class="row g-3 needs-validation" novalidate>
                                <div class="col-md-12">
                                    <label for="validationCustom01" class="form-label" onChange={handleName} value={name}>Noms complets</label>
                                    <input type="text" class="form-control" id="validationCustom01" required />
                                </div>

                                <div class="col-md-12">
                                    <label for="validationCustom03" class="form-label" onChange={handleAdresse} value={adresse}>Addresse</label>
                                    <input type="text" class="form-control" id="validationCustom03" required />
                                </div>

                                <div class="col-md-12">
                                    <label for="validationCustom03" class="form-label" onChange={handleTel} value={numero}>Nº Tel</label>
                                    <input type="text" class="form-control" id="validationCustom03" required />
                                </div>
                        </form>
                            </div>
                            <div className='payment__part'>
                            <h4>Nous acceptons :</h4>
                            <div className='payment__part__content'>
                                <img src="https://www3.easiware.fr/courir/Public/CRM/ILib_Image/R21/paiement.png" alt="" />
                            </div>
                            </div>
                            
                            <div className='payment__part'>
                            <h4>Option de paiement</h4>
                            <div className='payment__part__content'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Cash à la livraison
                                </label>
                                </div>
                                <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                <label class="form-check-label" for="flexRadioDefault2">
                                    Paiement par Money
                                </label>
                                </div>
                            </div>
                            </div>
                      </div>
                </div>
        </div>     
    </div>
        <button className='btn__payment' type='submit'>Procéder au paiement</button>  

        </div>
        <Footer/>
        </div>
    );
}