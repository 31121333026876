import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import NavLogin from '../components/NavLogin';
import { getCartTotal } from "../features/cartSlice";


function Navbar ({search,setSearch}) {
  const [user,setUser] = useState("");
  const [nav , setNav] = useState(false);
  const [cartShop , setCartShop] = useState(false);
  const [display,setDisplay]=useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { cart, totalQuantity } = useSelector((state) => state.allCart);
  

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCartTotal());
  }, [cart]);

    const openLog = ()=>{
      setNav(true)
    }

    const showCart =()=>{
      setCartShop (true)
  }

  const showProfil =()=>{
    setDisplay(true)
  }
  const closeProfil =()=>{
    setDisplay(false)
  }
  const showMenu = () =>{
    setDisplay(true)
}
const affi = ()=> {
  setDisplay (false)
}

  const logout =()=>{
    localStorage.clear();
    navigate('/');
  }
  const auth = localStorage.getItem("user");

  const handleSearch = (e)=>{
    setSearch(e.target.value)
  }
  const searching =(e)=>{
    navigate('/searching');
    setSearch(e.target.value)
  }

    return (
      <div>
      <header>

        { auth ? (
        <div className='navba'>
           <div className="navbar--left">
          <div className='icon--menu' onClick={showMenu}>
               <i className="fa-solid fa-bars"></i>
               </div>
            <div className="navbar--logo">
            <Link to="/" className='header__title'><h5>SHOPPING<span>BAG</span></h5></Link>
              {/* <Link to="/"> <img src={`${process.env.PUBLIC_URL}/easy.png`} alt=""/></Link> */}
              {/* <img src="" alt="" /> */}
            </div>
          </div>
          <div className="navbar--center">
            <div className="navbar--center--content">
              <div className="col-3 col-md-8">
                <form class="d-flex" role="search">
                <input class="form-control me-3" type="search" placeholder="Cherchez un produit , une marque ou une categorie..." aria-label="Search" onChange={handleSearch}/>
                <button class="btn btn-outline-success" onClick={searching}>Search</button>
                {/* <Link className='liste' to="/">Accueil</Link> */}
              </form>
              </div>
            </div>
            <div className='logo--resp'>
              <div className="navbar--logo--resp">
                {/* <Link to="/"> <img src={`${process.env.PUBLIC_URL}/easy.png`} alt=""/></Link> */}
                <Link to="/"> <img src={`${process.env.PUBLIC_URL}/delices.png`} alt=""/></Link>
              </div>
            </div>
          </div>

        
          <div className="navbar--right">
          <div className="navbar--right--content">
                       <NavLogin nav={nav} setNav={setNav}/>
                       <Link className='liste' ><button onClick={openLog} className="btn--connexion"><i className="fa-solid fa-user"></i> Mon profil </button></Link>
                  </div>
                  <Link to="/commandes"> <button className='btn__cart'><i className="fa-solid fa-bag-shopping"></i> Cart({totalQuantity})</button></Link>
          </div>
         </div>
        ) : (
          <div className='navba'>
          <div className="navbar--left">
          <div className='icon--menu' onClick={showMenu}>
               <i className="fa-solid fa-bars"></i>
               </div>
            <div className="navbar--logo">
            {/* <Link to="/" className='header__title'><h5>SHOPPING<span>BAG</span></h5></Link> */}
              <Link to="/"> <img src={`${process.env.PUBLIC_URL}/delices.png`} alt=""/></Link>
            </div>
          </div>
          <div className="navbar--center">
            <div className="navbar--center--content">
              <div className="col-3 col-md-8">
                <form class="d-flex" role="search">
                <input class="form-control me-3" type="search" placeholder="Cherchez un restaurant..." aria-label="Search" onChange={handleSearch}/>
                <button class="btn btn-outline-success" onClick={searching}>Search</button>
              </form>
              </div>
            </div>
            <div className='logo--resp'>
              <div className="navbar--logo--resp">
              <Link to="/"> <img src={`${process.env.PUBLIC_URL}/delices.png`} alt=""/></Link>
              </div>
            </div>
          </div>
          <div className="navbar--right">
          <div className="navbar--right--content">
                        <NavLogin nav={nav} setNav={setNav}/>
                       <button className="btn--connexion" onClick={openLog}>Se connecter</button>
                  </div>
                  <Link to="/commandes"> <button className='btn__cart'><i className="fa-solid fa-bag-shopping"></i> Cart({totalQuantity})</button></Link>
          </div>
         </div>
        )}

        <div className={display ? "menu show--menu" : "menu"}>
                    <div className='sidebar--menu'>
                        <div className='btn--close--sidebar' onClick={affi}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className='sidebar--header--logo'>
                            <div className='sidebar--header--logo--box'>
                                <img src="delices.png" alt="" />
                            </div>
                        </div>
                        <div className='menu__content'>
                          <div className='menu__content__liste'>
                            <Link className='list' to="/"><i className="fa-solid fa-bars"></i> Accueil</Link>
                          </div>
                          <div className='menu__content__liste'>
                          <Link className='list' to='/restaurant'><i className="fa-solid fa-utensils"></i> Restaurants</Link>
                          </div>
                          <div className='menu__content__liste'>
                          <Link className='list' to='/petit-dej'><i className="fa-solid fa-cookie-bite"></i> P'tit Dej & Snacks</Link>
                          </div>
                            <div className='menu__content__liste'>
                            <Link className='list' to='/supermarche'><i className="fa-solid fa-shop"></i> Supermarchés</Link>
                            </div>
                            <div className='menu__content__liste'>
                              <Link className='list' to='/burger--king'> <i className="fa-solid fa-burger"></i> Burger King</Link>
                            </div>
                            <div className='menu__content__liste'>
                            <Link className='list' to='/street--foods'><i className="fa-solid fa-burger"></i> Street Foods</Link>
                            </div>
                          <div className="col-12 col-md-12">
                            <form class="d-flex" role="search">
                            <input class="form-control me-2" type="search" placeholder="Cherchez un produit , une marque ou une categorie..." aria-label="Search"/>
                            <button class="btn btn-outline-success" type="submit">Search</button>
                          </form>
                          </div>
                        </div>
                        </div>

        </div>

    </header>
          

              </div>
    );
}

export default Navbar;
