import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import 'animate.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import ProductCart from '../components/ProductCart';
import Marquee from "react-fast-marquee";

export default function Accueil () {
    const dispatch = useDispatch()
  const [display,setDisplay]= useState(false);
  

  const [cart,setCart]= useState(false);

  const closeCart = ()=> {
    setCart (false)
}
const showCart =()=>{
    setCart (true)
}
  // const newArrivages = useSelector(state=>state.categoryReducer.newArrivages);
  // const newsProducts = useSelector(state=>state.categoryReducer.newsProducts);
  // const carts = useSelector(state => state.cartReducer.carts);

    //   useEffect(()=>{
    //     localStorage.setItem("carts",JSON.stringify(carts));
    // })

    return (
      <div>
                <Marquee className="marquee" speed={80}>
                        -20% sur votre première commande avec le code promo DOUM2023
                 </Marquee>

               <Navbar/>
          <div className='address__container'>
            <h1 className='address__container__header'>Tout ce qu'il vous faut, livré maintenant</h1>
            <div className='address__input'>
              <div className="inp__mb">
              <div class="form-floating mb-3">
              <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
              <label for="floatingInput">Quel est votre addresse ?</label>
              </div>
              <button className='search'>Rechercher</button>
          </div>
            </div>
          </div>
          <div className='bubble__container'>
            <div className='bubble__content'>
            <div className='bubbles'>
                <div className='bubbles__images'>
                  <div className='bubbles__images__box'>
                  <Link to='/restaurant'><img src="https://res.cloudinary.com/glovoapp/c_fit,f_auto,e_trim,q_auto:best,h_120,w_120/StoreCategories/uujfkigslkcloknfxzxx" alt="" /></Link>
                  </div>
                </div>
                <div className='bubbles__body'>
                <h6 className='bubbles__title'>Restaurants</h6>
                </div>
              </div>
              {/* <div className='bubbles'>
                <div className='bubbles__images'>
                  <div className='bubbles__images__box'>
                  <Link to='/jai--faim'><img src="https://res.cloudinary.com/glovoapp/c_fit,f_auto,e_trim,q_auto:best,h_120,w_120/CategoryGroups/sdnlch2qzcogux9xpmuj" alt="" /></Link>
                  </div>
                </div>
                <h6 className='bubbles__title' >J'ai faim</h6>
              </div> */}
              <div className='bubbles'>
                <div className='bubbles__images'>
                  <div className='bubbles__images__box'>
                  <Link to='/petit-dej'><img src="https://res.cloudinary.com/glovoapp/c_fit,f_auto,e_trim,q_auto:best,h_120,w_120/StoreCategories/tswludnwhgalmelckhi8" alt="" /></Link>
                  </div>
                </div>
                <h6  className='bubbles__title'>P'tit déj et Snacks</h6>
              </div>
              <div className='bubbles'>
                <div className='bubbles__images'>
                  <div className='bubbles__images__box'>
                  <Link to='/supermarche'><img src="https://res.cloudinary.com/glovoapp/c_fit,f_auto,e_trim,q_auto:best,h_120,w_120/CategoryGroups/rofumn3e5icxoqoqh0gd" alt="" /></Link>
                  </div>
                </div>
                <h6 className='bubbles__title'>Supermarché</h6>
              </div>
              {/* <div className='bubbles'>
                <div className='bubbles__images'>
                  <div className='bubbles__images__box'>
                  <img src="https://res.cloudinary.com/glovoapp/c_fit,f_auto,e_trim,q_auto:best,h_120,w_120/CategoryGroups/ffrpoca7vlbn4wyozn1s" alt="" />
                  </div>
                </div>
                <h6 className='bubbles__title'>Livraison sur mesure</h6>
              </div> */}
              <div className='bubbles'>
                <div className='bubbles__images'>
                  <div className='bubbles__images__box'>
                  <Link to='/burger--king'><img src="https://res.cloudinary.com/glovoapp/c_fit,f_auto,e_trim,q_auto:best,h_120,w_120/StoreCategories/x2dppkix1q8rrvmtwrtn" alt="" /></Link>
                  </div>
                </div>
                <h6 className='bubbles__title'>Burger King</h6>
              </div>
              <div className='bubbles'>
                <div className='bubbles__images'>
                  <div className='bubbles__images__box'>
                  <Link to='/street--foods'><img src="https://res.cloudinary.com/glovoapp/c_fit,f_auto,e_trim,q_auto:best,h_120,w_120/StoreCategories/nvw5aci1dmrclf7y2l3o" alt="" /></Link>
                  </div>
                </div>
                <div className='bubbles__body'>
                <h6 className='bubbles__title'>Street Foods</h6>
                </div>
              </div>
             
            </div>
          </div>

          <div className='container'>
          <div className='widget__wrapper__container'>
              <h3 className='widget__wrapper__header'>Meilleures catégories</h3>
              <div className='widget__wrapper'>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Poulet</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/aeeq8dlsvcw6z9ruzmmz" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>KFC</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 93% (500+)</p>
                    </div>
                </div>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Burgers</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/aed8kfrca76fxlfket0t" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>Burgers King</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 93% (500+)</p>
                    </div>
                </div>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Libanais</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/og6fi2lacbnbmigob41n" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>Le petit café</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 90% (500+)</p>
                    </div>
                </div>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Sweets</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/fyvqewjdouzfisyjofkh" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>DipnDip</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 95% (500+)</p>
                    </div>
                </div>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Libanais</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/xnpivpsnsi5smnyxqsaw" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>Roi du chawarma</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 80% (120+)</p>
                    </div>
                </div>

              </div>
          </div>

          <div className='widget__wrapper__container'>
              <h3 className='widget__wrapper__header'>Vous pourriez aimer</h3>
              <div className='widget__wrapper'>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Poulet</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/aeeq8dlsvcw6z9ruzmmz" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>KFC</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 93% (500+)</p>
                    </div>
                </div>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Burgers</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/aed8kfrca76fxlfket0t" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>Burgers King</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 93% (500+)</p>
                    </div>
                </div>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Libanais</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/og6fi2lacbnbmigob41n" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>Le petit café</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 90% (500+)</p>
                    </div>
                </div>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Sweets</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/fyvqewjdouzfisyjofkh" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>DipnDip</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 95% (500+)</p>
                    </div>
                </div>
                <div className='widget__cards'>
                    <div className='widget__cards__header'>
                    <p className='food__name'>Libanais</p>
                        <img src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,h_250,w_450/Stores/xnpivpsnsi5smnyxqsaw" alt="" />
                    </div>
                    <div className='widget__cards__body'>
                        <h6 className='widget__title'>Roi du chawarma</h6>
                        <p className='liked'><i class="fa-solid fa-thumbs-up"></i> 80% (120+)</p>
                    </div>
                </div>

              </div>
          </div>
{/*         
              <ProductCart/> */}
          </div>
          <div className='section--how--it--works'>
            <h3 className='section-works-title'>Comment ça marche ?</h3>
              <div className='works__container'>
                <div className='works__cards'>
                 <div className='works__cards__images'>
                  <img src="https://food.jumia.ci/images/how-it-works-1.svg?v=1596116892" alt="" />
                 </div>
                 <h3 className='works__cards__title'>Sélectionnez votre zone de livraison</h3>
                 <p>Renseignez l'adresse où vous souhaitez être livré</p>

                </div>

                <div className='works__cards'>
                  <div className='works__cards__images'>
                    <img src="https://food.jumia.ci/images/how-it-works-2.svg?v=1596116892" alt="" />
                  </div>
                      <h3 className='works__cards__title'>Sélectionnez votre zone de livraison</h3>
                    <p>Renseignez l'adresse où vous souhaitez être livré</p>
                </div>

                <div className='works__cards'>
                 <div className='works__cards__images'>
                  <img src="https://food.jumia.ci/images/how-it-works-3.svg?v=1596116892" alt="" />
                 </div>
                  <h3 className='works__cards__title'>Sélectionnez votre zone de livraison</h3>
                  <p>Renseignez l'adresse où vous souhaitez être livré</p>
                </div>

              </div>
          </div>

          <div className='section--oportunity'>
            <div className='section--oportunity--header'>
              <img src="https://res.cloudinary.com/glovoapp/image/fetch/f_svg,c_lpad,q_auto:low,h_80,w_117,b_transparent/https://glovoapp.com/images/corporate-container/together.svg" alt="" />
            </div>
          <h3 className='section--oportunity--title'>Opportunités</h3>
              <div className='section--oportunity__container'>
                <div className='oportunity__cards'>
                 <div className='oportunity__cards__images'>
                 <img src="https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_2.0,h_220,w_254,b_transparent/https://glovoapp.com/images/corporate-container/rider-image.png" alt="" />
                 </div>
                 <h3 className='oportunity__cards__title'>Devenir coursier</h3>
                 <p>C'est vous le chef ! Livrez avec <strong>Délices House</strong> pour gagner des revenus compétitifs en toute flexibilité et liberté.</p>
                 <button className='btn__join'>Rejoignez-nous</button>
                </div>

                <div className='oportunity__cards'>
                 <div className='oportunity__cards__images'>
                  <img src="https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_2.0,h_220,w_254,b_transparent/https://glovoapp.com/images/corporate-container/partners-image.png" alt="" />
                 </div>
                 <h3 className='oportunity__cards__title'>Devenir partenaire</h3>
                 <p>Grandissez avec <strong>Délices House</strong> ! Boostez les ventes et accédez à de nouvelles 
                  opportunités grâce à notre technologie et à notre base d'utilisateurs !</p>
                  <button className='btn__join'>Rejoignez-nous</button>
                </div>

                <div className='oportunity__cards'>
                 <div className='oportunity__cards__images'>
                  <img src="https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_2.0,h_220,w_254,b_transparent/https://glovoapp.com/images/corporate-container/careers-image.png" alt="" />
                 </div>
                 <h3 className='oportunity__cards__title'>Emploi</h3>
                 <p>Vous cherchez un nouveau défi ? Si vous faites preuve d'ambition et d'humilité et aimez travailler en équipe, contactez-nous !</p>
                    <button className='btn__join'>Rejoignez-nous</button>
                </div>

              </div>

          </div>
          <div className='part__newsletter'>
            <div className='part__newsletter__content'>
              <h2 className='news__header'>-20% sur votre première commande en vous abonnant à nos newsletters !</h2>
              <p>Ne manquez pas nos excellentes offres & Recevez des offres de tous nos meilleurs vendeurs par e-mail</p>
                <div className='news__container'>
                      <div className='news__container__content'>
                      <div className="'news__container__input">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Sélectionnez votre ville</option>
                      <option value="1">Abidjan</option>
                    </select>
                    </div>
                    <div class="form-floating col-md-3">
                      <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                      <label for="floatingInput">Addresse email</label>
                    </div>
                      <button className='btn__newsletter'>Abonnez-vous à la newsletter</button>
                      </div>
                </div>
            </div>

          </div>
          <Footer/>
        </div>

    );
}

