import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
// import {addToCart, getAllArticles} from "../Redux/actions";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ProductCart from "../components/ProductCart";
import Slider from '../components/Slider';
import Marquee from "react-fast-marquee";
import Filter from "./Filter";
import { addToCart } from "../features/cartSlice";
import data from "../data";
import Bar from "../components/Bar";
import DetailFood from "./DetailFood";



export default function Restaurant () {

    const dispatch = useDispatch();
    const [dis,setDis]=useState(true)
    const [filter,setFilter] = useState(false);
    const [cat,setCat]=useState(data);
    const [detail,setDetail]=useState(false)
    const items = useSelector((state) => state.allCart.items);

    const filterResult = (catItem)=>{
      const filterData = data.filter((item) =>  item.title === catItem )
          setCat(filterData);
          setDis(false)
          console.log(catItem)
  }

  const closeFilter = ()=> {
        setFilter (false)
    }
    const showFilter =()=>{
        setFilter (true)
    }

    const showDetail = ()=>{
     setDetail(true);
    }
//     

// useEffect(() => {
//      fetch('http://127.0.0.1:4000/product')
//      .then((res)=>res.json())
//      .then((cat)=>{dispatch(addToCart(cat))})
//      .catch(e => { console.log(e)})
//      setTimeout(()=>{setLoading(false)},1500);   
//      }, [])

     console.log(cat)

//     const handleChange = (event)=>{
//             setSearch(event.target.value)
//         }
     
    return (
        <div>
          <Marquee className="marquee">-20% sur votre première commande</Marquee>

            <Navbar/>
            <Bar/>
            <div className="container">
          
            </div>
            <div className="second__part__header">
               <img src="https://img.freepik.com/photos-gratuite/vue-dessus-delicieux-rouleaux-aubergines-plat-cuit-pommes-terre-differents-assaisonnements-surface-sombre-plat-repas-diner-nourriture_140725-118432.jpg?w=1060&t=st=1699286069~exp=1699286669~hmac=e9a2d584cdbbe5d86b8cd53a331e951b98a1e9512dfbe2fead45b10d3af1ca4b" alt="" />
                        <div className="second__part__header__content">
                        <p className="second__part__title">Restaurant </p>
                        <div className="second__part__description">
                         <p>Min. commande 4.000 CFA - Livraison à partir de 500 CFA</p>
                         <p className="during">30-40 minutes</p>
                        </div>
                        </div>
                </div>
                <div>
             <div className="filter" onMouseOver={showFilter}>
                        <i className="fa-solid fa-arrow-up-wide-short"></i>
                        <p>Trier</p>
            </div>
                <div className={filter ? "filterbar show--filterbar" : "filterbar"}>
                        <div className='filterbar--content' onMouseLeave={closeFilter}>
                          <div className='btn--close--sidebar'onClick={closeFilter}> <i className="fa-solid fa-xmark"></i></div>

                          <div className='filter--sidebar'>
                          <div className="filter--container">
                <h6 className="filter--section--title">Trier par :</h6>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/sorting/near_me_light" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Autour de moi</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/sorting/rating_light" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Meilleur note</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/sorting/delivery_fee_light" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Frais de livraison</p>         
            </div>

            <h6 className="filter--section--title">Filtres populaires</h6>
            {/* <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/inwbt21iyiizggz1alhk" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Burgers')}}>Burgers</p>         
            </div> */}
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/rwr4tfqs1uxtatfuguvb" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Pizza')}}>Pizza</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/qurrxvfazkfr9kglwl8t" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Chawarma')}}>Chawarma</p>         
            </div>

            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/n2az4lwfiypajqgok9ey" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Crêpes')}}>Crêpes</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/tvhfoysp3bp6xfgxpsn6" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Cuisines locales')}}>Cuisines locales</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/jwdvk8qh8gyrn6aavkl4" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Fruits de mer</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/dsu6knoymqxjgsvycjlv" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Grillades</p>         
            </div>
            {/* <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/fprtz4opfsanft2hi8cg" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Pâtisserie</p>         
            </div> */}
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/i8abjcvwjxylemfkkznj" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Poulet</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/zuurobratonms7fd5cbn" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Sandwichs</p>         
            </div>
            {/* <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/jwdvk8qh8gyrn6aavkl4" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Sweets</p>         
            </div> */}
         
                </div>
                            </div>
                          </div>
                  </div>

             </div>

        <div className="container-contents">
          <div className="sidebar">
          <div className="filter--container">
                {/* <h6 className="filter--section--title">Trier par :</h6> */}
            {/* <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/sorting/near_me_light" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Autour de moi</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/sorting/rating_light" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Meilleur note</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/sorting/delivery_fee_light" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Frais de livraison</p>         
            </div> */}

            <h6 className="filter--section--title">Filtres populaires</h6>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/inwbt21iyiizggz1alhk" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>setCat(data)}>All</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/rwr4tfqs1uxtatfuguvb" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Cheese Burger')}}>Pizza</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/qurrxvfazkfr9kglwl8t" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Chawarma')}}>Chawarma</p>         
            </div>

            {/* <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/n2az4lwfiypajqgok9ey" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Crêpes</p>         
            </div> */}
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/tvhfoysp3bp6xfgxpsn6" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Chawarma')}}>Cuisines locales</p>         
            </div>
            {/* <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/kxhu6lacrxernpqacaqf" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Desserts</p>         
            </div> */}
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/jwdvk8qh8gyrn6aavkl4" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Garba')}}>Fruits de mer</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/dsu6knoymqxjgsvycjlv" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Poulet')}}>Grillades</p>         
            </div>
            {/* <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/fprtz4opfsanft2hi8cg" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Pâtisserie</p>         
            </div> */}
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/i8abjcvwjxylemfkkznj" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Crepes')}}>Poulet</p>         
            </div>
            <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/zuurobratonms7fd5cbn" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Cheese Burger')}}>Sandwichs</p>         
            </div>
            {/* <div className="filter--section">
                   <div className="filter--section__pic">
                        <img src="https://res.cloudinary.com/glovoapp/q_30,c_pad,f_auto,dpr_1.0,h_22,w_22,b_transparent/filters/jwdvk8qh8gyrn6aavkl4" alt="" />
                   </div>
                   <p className="btn__filter" onClick={()=>{filterResult('Montre')}}>Sweets</p>         
            </div> */}
         
                </div>
            </div>


          {/* <DetailFood detail={detail} setDetail={setDetail} /> */}

       <div className="part__products">
       <div className="second__part">
                <div className='category__cards'>
                {cat.map((item) => ( <div key={item.id} className='category__cards__body'>
                        <div className="card__body__left">
                              <div className="card__body__left__img">
                              {/* <Link to={`/detailFood/${item.id}`}><button onClick={showDetail}>voir</button></Link> */}
                                        {/* <button onClick={showDetail}>voir</button> */}
                                        <Link to={`/detail/${item.id}`}><img src={item.img} alt="" /></Link>
                              </div>
                        </div>
                        <div className="card__body__center">
                        <h6 className='card__name'>{item.title}</h6>
                        <p className="card__description">{item.description}</p>
                        <p className="card__price">{item.price} FCFA</p>
                        </div>
                        <div className="card__body__right">
                                <div className="card__body__right__box">
                                <button className="btn__add__cart" onClick={() => dispatch(addToCart(item))}>+</button>
                                </div>
                        </div>
                    </div>
                    ))}
                    
                </div>

                    
          </div>
       </div>
          
          </div>
        <Footer/>
        </div>
    )
}