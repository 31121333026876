import React, { useEffect ,useState} from 'react';
import Navbar from './Navbar';
import {Link, useParams} from 'react-router-dom';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import ProductCart from '../components/ProductCart';
import data from '../data';
import {getCartTotal,removeItem,decreaseItemQuantity,increaseItemQuantity,addToCart
} from "../features/cartSlice";



export default function DetailFood ({setDetail,detail}) {
    var id = useParams().id;
    const dispatch = useDispatch();
    const { cart, totalQuantity, totalPrice } = useSelector((state) => state.allCart
    );
  
    useEffect(() => {
      dispatch(getCartTotal());
    }, [cart]);

    const displayFood = ()=>{
        setDetail(false)
    }
//     

//     useEffect(() => {
//         fetch('http://127.0.0.1:8080/men')
//         .then((res)=>res.json())
//         .then((products)=>{dispatch(getAllArticles(products))
//         })
//         .catch(e => { console.log(e)})
//         }, [])


//     const handleChange = (event)=>{
//             setSearch(event.target.value)
//         }
     
    return (
        <div>
        <div className={detail ? "detail__foods show--detail" : "detail__foods"}>
               <div className="btn__xmark">
                    <i class="fa-solid fa-xmark" onClick={displayFood}></i>
               </div>
            <div key={id} className='container__product__details'>
                  <div className='container__product__details__left'>
                    <div className='container__product__details__left__content'>
                    <div className='detail__left__images'>
                        <div className='detail__left__images__box'>
                          <img src={cart.img} alt="" />
                        </div>
                      </div>
                      <div className='detail__left__description'>
                            <h3 className='product__title__name'>{cart.title}</h3>
                            <p>{cart.price} FCFA</p>
                            <p>{data.description}</p>
                            <div className='button__block'>
                              <div className='button__block__content'>
                              <button className='btn__quantity'onClick={() => dispatch(decreaseItemQuantity(item.id))}>-</button>
                              <p className='total__qty'>{data.quantity}</p>
                              <button className='btn__quantity'onClick={() => dispatch(increaseItemQuantity(item.id))}>+</button>
                              </div>
                            </div>
                      </div>
                    </div>
                  </div>
                  <div className='container__product__details__right'>
                      <div className='container__product__details__right__content'>
                          <h5 className='title'>Souhaitez vous une boisson ?</h5>
                          <p className='subtitle'>Choisissez au maximum 5 options</p>
                          <div className='product__list'>
                             <div className='foods__drink'>
                              <p>Coca-cola +1000 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                             <div className='foods__drink'>
                              <p>Fanta +1000 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                             <div className='foods__drink'>
                              <p>Youki Moka +1000 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                          </div>
                          <h5 className='title'>Souhaitez vous une sauce en supplément ?</h5>
                          <p className='subtitle'>Choisissez au maximum 7 options</p>
                          <div className='product__list'>
                             <div className='foods__drink'>
                              <p>Sauce BBQ +500 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                             <div className='foods__drink'>
                              <p>Dynamite Sauce +500 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                             <div className='foods__drink'>
                              <p>Sauce Mexicaine +1000 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                          </div>
                          <button className='btn__add'onClick={() => dispatch(addToCart(item))}>Ajouter pour 8 000 FCFA</button>
                      </div>
                  </div>
          </div>
            </div>
          
        </div>
    )
}