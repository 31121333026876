import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom";



export default function Bar () {

    return (
        <div className="bar">
            <Link to='/'><p className="bar__link"><i class="fa-solid fa-house"></i> Accueil</p></Link>
            <Link to='/restaurant'><p className="bar__link"><i className="fa-solid fa-utensils"></i> Restaurant</p></Link>
            <Link to='/petit-dej'><p className="bar__link"><i className="fa-solid fa-cookie-bite"></i> Petit-Déj</p></Link>
            <Link to='/supermarche'><p className="bar__link"><i className="fa-solid fa-shop"></i> Supermarché</p></Link>
            <Link to='/burger--king'><p className="bar__link"><i className="fa-solid fa-burger"></i> Burger King</p></Link>
            <Link to='/street--foods'><p className="bar__link"><i className="fa-solid fa-burger"></i> Street Foods</p></Link>
        </div>
    )
}