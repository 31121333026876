import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./Pages/Navbar";
import ProductCard from "./components/ProductCard";
import CartPage from "./components/cartPage";
import Commandes from '../src/Pages/Commandes';
import Accueil from "./Pages/Accueil";
import Supermarche from "./Pages/Supermarche";
import Restaurant from "./Pages/Restaurant";
import Dejeuner from "./Pages/Dejeuner";
import Burger from "./Pages/Burger";
import Street from "./Pages/Street";
import Detail from "./Pages/Detail";
import DetailFood from "./Pages/DetailFood";


function App() {
  return (
      <div>
        <Routes>
          <Route exact path="/" element={<Accueil />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/commandes" element={<Commandes/>} />
          <Route path="/supermarche" element={<Supermarche/>} />
          <Route path="/restaurant" element={<Restaurant/>} />
          <Route path="/petit-dej" element={<Dejeuner />} />
          <Route path="/burger--king" element={<Burger/>} />
          <Route path="/street--foods" element={<Street/>} />
          <Route path="/detail/:id" element={<Detail/>} />
          <Route path="/detailFood/:id" element={<DetailFood/>} />
        </Routes>
      </div>
  );
}

export default App;
