import React, { useEffect } from 'react';
import Navbar from './Navbar';
import {Link, useParams} from 'react-router-dom';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import ProductCart from '../components/ProductCart';
import data from '../data';
import {getCartTotal,removeItem,decreaseItemQuantity,increaseItemQuantity,addToCart
} from "../features/cartSlice";

export default function Detail () {

  const dispatch = useDispatch();
  let id = useParams().id;
  const item = data.find(items => items.id == id);
  const { cart, totalQuantity, totalPrice } = useSelector((state) => state.allCart
  );

  useEffect(() => {
    dispatch(getCartTotal());
  }, [cart]);

            
    return (
     <div>
        <Navbar/>
        <div className='container'>
          <div className='container__product__details'>
                  <div className='container__product__details__left'>
                    <div className='container__product__details__left__content'>
                    <div className='detail__left__images'>
                        <div className='detail__left__images__box'>
                          <img src={`${process.env.PUBLIC_URL}/${item.img}`}  alt="" />
                        </div>
                      </div>
                      <div key={id} className='detail__left__description'>
                            <h3 className='product__title__name'>{item.title}</h3>
                            <p>{item.price} FCFA</p>
                            <p>{item.description}</p>
                            <div className='button__block'>
                              <div className='button__block__content'>
                              <button className='btn__quantity'onClick={() => dispatch(decreaseItemQuantity(item.id))}>-</button>
                              <p className='total__qty'>{item.quantity}</p>
                              <button className='btn__quantity'onClick={() => dispatch(increaseItemQuantity(item.id))}>+</button>
                              </div>
                            </div>
                      </div>
                    </div>
                  </div>
                  <div className='container__product__details__right'>
                      <div className='container__product__details__right__content'>
                          <h5 className='title'>Souhaitez vous une boisson ?</h5>
                          <p className='subtitle'>Choisissez au maximum 5 options</p>
                          <div className='product__list'>
                             <div className='foods__drink'>
                              <p>Coca-cola +1000 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                             <div className='foods__drink'>
                              <p>Fanta +1000 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                             <div className='foods__drink'>
                              <p>Youki Moka +1000 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                          </div>
                          <h5 className='title'>Souhaitez vous une sauce en supplément ?</h5>
                          <p className='subtitle'>Choisissez au maximum 7 options</p>
                          <div className='product__list'>
                             <div className='foods__drink'>
                              <p>Sauce BBQ +500 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                             <div className='foods__drink'>
                              <p>Dynamite Sauce +500 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                             <div className='foods__drink'>
                              <p>Sauce Mexicaine +1000 FCFA</p>
                              <div className='buttons'>
                              <button className='btns'>-</button>
                                <p className='total'>0</p>
                                <button className='btns'>+</button>
                            </div>
                             </div>
                          </div>
                          <button className='btn__add'onClick={() => dispatch(addToCart(item))}>Ajouter</button>
                      </div>
                  </div>
          </div>
          {/* <ProductCart/> */}
            

         
        </div>
        <Footer/>
     </div>
    );
}

